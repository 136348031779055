@import "smart-grid";

.txt-page {
    .db-wrp {
        display: flex;
        flex-wrap: wrap;

        &._black {
            .content {
                padding-top: 0;
            }

            p {
                font-size: 18px;
                color: #FFFFFF;
            }

            .line {
                background-color: #CC1D2E;
                width: 40px;
                height: 6px;
                margin: 50px auto 29px 0;

                &._long {
                    width: 110px;
                }
            }
        }

        .content {
            width: 71%;
            padding-right: 50px;
            padding-top: 70px;
            @include xmd-block(){
                width: 100%;
                padding-right: 0;
            }


            &._pt115 {
                padding-top: 115px;
                @include xmd-block(){
                    padding-top: 2.5rem;
                }
            }

            &._pt0 {
                padding-top: 0;
            }

            h4, .h4 {
                font-weight: bold;
                font-size: 24px;
                line-height: 117%;
                margin-bottom: 45px;
                color: #000000;
            }

            ul {
                display: flex;
                flex-wrap: wrap;
                gap: 30px 0;
                margin: 30px 0;
                @include xxs-block(){
                    margin: 15px 0;
                    gap: 15px 0;
                }

                li {
                    width: 50%;
                    padding-right: 30px;
                    margin-bottom: 0;
                    @include xxs-block(){
                        width: 100%;
                    }
                }

                &._wht {
                    gap: 0;

                    li {
                        color: #fff;
                    }

                }

                &._big {
                    gap: 0;

                    li {
                        width: 50%;
                        padding-left: 50px;
                        font-weight: bold;
                        font-size: 36px;
                        position: relative;
                        line-height: 1;
                        @include xxs-block(){
                            width: 100%;
                            font-size: 22px;
                        }

                        &::before {
                            content: '';
                            width: 11px;
                            height: 11px;
                            border-radius: 50%;
                            background-color: #fff;
                            position: absolute;
                            top: 50%;
                            left: 24px;
                            transform: translateY(-50%);
                        }
                        @include from($break_xxs + 1){
                            &:nth-child(n+3) {
                                margin-top: 60px;

                            }
                        }
                        @include xxs-block(){
                            &:not(:last-child){
                                margin-bottom: 10px;
                            }
                        }


                    }

                }


            }
        }

        .sidebar {
            width: 29%;
            display: flex;
            flex-wrap: wrap;
            position: relative;
            align-items: flex-start;
            @include xmd-block(){
                width: 100%;
                padding: 20px;
            }

            img {
                margin: auto;
            }

            img._absolute {
                @include from($break_xmd + 1){
                    position: absolute;
                    right: 0;
                    top: 13px;
                    min-width: calc(100% + 50px);
                }

            }

            &._blue {
                background-color: #0063A6;
                @include xxs-block(){
                    min-width: calc(100% + 2rem);
                    margin-left: -1rem;
                }

            }

            &._blueGradient {
                background: linear-gradient(
                                178.92deg, #0063A6 46.27%, rgba(39, 118, 172, 1) 56.82%, rgba(70, 134, 177, 0.7) 75.5%, rgba(196, 196, 196, 0) 90%);
                @include xmd-block(){
                    padding-right: 0;
                }
            }

            .text-arrow {
                margin: auto auto 0;
                color: #fff;
                font-weight: bold;
                font-size: 40px;
                text-align: center;
                padding-bottom: 35px;
                line-height: 120%;
                @include xmd-block(){
                    font-size: 30px;
                }
                @include xxs-block(){
                    padding-bottom: 0;
                }

                img {
                    margin: 34px auto 0;
                    animation: 1s linear toBottom infinite;
                }
            }

            .qr-block {
                width: 214px;
                height: 214px;
                background-color: #fff;
                border-radius: 30px;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 24px;
                color: #000000;
                line-height: 33px;
                padding: 20px;
                position: relative;

                &__img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }

    .zag._blue {
        background-color: #0063A6;
        color: #fff;
        margin-bottom: 70px;
        @include xxs-block(){
            padding-left: 1rem;
        }

        .container {
            margin: 0 auto;
            display: flex;
            align-items: center;
            padding: 23px 0;
        }

        h2, .h2 {
            font-weight: bold;
            font-size: 40px;
            color: #fff;
            @include xxs-block(){
                font-size: 26px;
            }
        }

        .arrows-anim {
            display: flex;
            padding-left: 23px;

            img {
                margin-right: 7px;
                @include xxs-block(){
                    max-width: 10px;
                    margin-right: 5px;
                }
                &:nth-child(1) {

                }

                &:nth-child(2) {
                    opacity: 0.8;
                }

                &:nth-child(3) {
                    opacity: 0.6;
                }

                &:nth-child(4) {
                    opacity: 0.4;
                }
            }
        }
    }

    .note {
        margin-top: 70px;
        min-height: 35px;
        display: flex;
        align-items: center;
        padding: 0px 10px 0px 40px;
        background-image: url(../assets/images/new/arrow-red-bold.svg);
        background-size: 18px;
        background-repeat: no-repeat;
        background-position: left center;
        font-size: 18px;
        color: #6B7280;
        @include xxs-block(){
            font-size: 16px;
            margin-top: 40px;
        }
    }

    ul {
        display: flex;
        flex-wrap: wrap;

        &._wArrows {
            gap: 45px 0;
            @include xxs-block(){
                gap: 20px 0;
            }
            .note {
                margin-top: 0;
            }
        }

        &._w50 {
            li {
                width: 50%;
                @include xxs-block(){
                    width: 100%;
                }
            }
        }
    }

    .note-img {
        display: flex;
        align-items: flex-start;
        margin-bottom: 70px;
        @include xxs-block(){
            margin-bottom: 30px;
            flex-wrap: wrap;
        }

        img {
            width: 91px;
            height: auto;
            @include xxs-block(){
                margin: 0 auto 10px;
            }
        }

        p {
            max-width: calc(100% - 91px);
            padding-left: 48px;
            font-size: 21px;
            color: #6B7280;
            padding-top: 10px;
            @include xxs-block(){
                max-width: 100%;
                width: 100%;
                font-size: 14px;
                padding-left: 0;
            }

        }
    }

    .m100 {
        margin: 50px auto;
        display: flex;
        @include xxs-block(){
            margin: 25px auto;
        }
    }

    .m140 {
        margin: 70px auto;
        display: flex;
        @include xxs-block(){
            margin: 35px auto;
        }
    }

    .manager {
        display: flex;
        padding: 80px 160px 85px 105px;
        margin-bottom: 40px;
        position: relative;
        filter: drop-shadow(0px 7px 11px rgba(0, 0, 0, 0.25));
        @include xmd-block(){
            padding:30px;
        }
        @include xxs-block(){
            flex-wrap: wrap;
        }

        &::before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            clip-path: polygon(0 0, 100% 0, 100% 71%, 92% 100%, 0 99%, 0% 50%);
            background-color: #fff;
            z-index: 1;
            @include xxs-block(){
                clip-path: polygon(0 0, 100% 0, 100% 87%, 92% 100%, 0 100%, 0% 50%);
            }
        }

        &:after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
            background-image: url(/assets/images/new/bg-img.png);
            background-position: top right 150px;
            background-repeat: no-repeat;
        }

        &:not(:first-child) {
            margin-top: 140px;
            @include xmd-block(){
                margin-top: 70px;
            }
        }

        &._black {
            &::before {
                background-color: #021019;
            }

            &::after {
                background-image: url(/assets/images/new/bg-img-2.png);
            }

            .manager-content, .manager-img {
                background-image: url(../assets/images/new/arrow-white-bold.svg);
            }

            .name {
                font-weight: bold;
                color: #fff;
            }

            .position {
                color: #fff;
            }

            a {
                font-size: 24px;
                color: #fff;
                @include sm-block(){
                    font-size: 18px;
                }
                @include xxs-block(){
                    font-size: 14px;
                }
            }
        }

        &-img {
            width: 540px;
            background-image: url(../assets/images/new/arrow-red-bold.svg);
            background-position: left top;
            background-repeat: no-repeat;
            background-size: 35px;
            padding-left: 45px;
            position: relative;
            z-index: 3;
            @include xmd-block(){
                width: 30%;
                background-size: 20px;
                padding-left: 30px;
            }
            @include xxs-block(){
                width: 100%;
            }

            img {
                border-radius: 50%;
            }
        }

        &-content {
            display: flex;
            flex-wrap: wrap;
            align-content: space-between;
            padding-left: 75px;
            background-image: url(../assets/images/new/arrow-red-bold.svg);
            background-position: left top;
            background-repeat: no-repeat;
            background-size: 35px;
            padding-top: 20px;
            position: relative;
            z-index: 3;
            width: calc(100% - 540px);
            @include xmd-block(){
                padding-left: 50px;
                background-size: 20px;
                padding-top: 10px;
                width: 70%;
            }
            @include xxs-block(){
                width: 100%;
                padding-left: 30px;
            }
        }


        .name {
            font-size: 36px;
            margin-bottom: 25px;
            color: #111827;
            line-height: 120%;
            @include xmd-block(){
                font-size: 28px;
            }
            @include xxs-block(){
                font-size: 18px;
                margin-bottom: 10px;
            }
        }

        .position {
            font-size: 18px;
            margin-bottom: 20px;
            color: #CC1D2E;
            @include xxs-block(){
                font-size: 14px;
            }
        }

        a {
            margin-top: 15px;
            font-size: 18px;
            color: #111827;
            display: block;
            @include xxs-block(){
                font-size: 14px;
                margin-top: 5px;
            }
            &:hover {
                text-decoration: underline;
            }
        }

        &-note {
            margin: 40px 0 50px;
            font-size: 20px;
            color: #000000;
            line-height: 1.4;
            @include xxs-block(){
                margin: 20px 0 30px;
                font-size: 16px;
            }
        }
    }
}


@keyframes toBottom {
    0%, 100%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(10px);
    }
}
