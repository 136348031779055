/* line 4, dev/sass/custom.scss */
.txt-page .db-wrp {
  display: flex;
  flex-wrap: wrap;
}

/* line 9, dev/sass/custom.scss */
.txt-page .db-wrp._black .content {
  padding-top: 0;
}

/* line 13, dev/sass/custom.scss */
.txt-page .db-wrp._black p {
  font-size: 18px;
  color: #FFFFFF;
}

/* line 18, dev/sass/custom.scss */
.txt-page .db-wrp._black .line {
  background-color: #CC1D2E;
  width: 40px;
  height: 6px;
  margin: 50px auto 29px 0;
}

/* line 24, dev/sass/custom.scss */
.txt-page .db-wrp._black .line._long {
  width: 110px;
}

/* line 30, dev/sass/custom.scss */
.txt-page .db-wrp .content {
  width: 71%;
  padding-right: 50px;
  padding-top: 70px;
}

@media screen and (max-width: 1280px) {
  /* line 30, dev/sass/custom.scss */
  .txt-page .db-wrp .content {
    width: 100%;
    padding-right: 0;
  }
}

/* line 40, dev/sass/custom.scss */
.txt-page .db-wrp .content._pt115 {
  padding-top: 115px;
}

@media screen and (max-width: 1280px) {
  /* line 40, dev/sass/custom.scss */
  .txt-page .db-wrp .content._pt115 {
    padding-top: 2.5rem;
  }
}

/* line 47, dev/sass/custom.scss */
.txt-page .db-wrp .content._pt0 {
  padding-top: 0;
}

/* line 51, dev/sass/custom.scss */
.txt-page .db-wrp .content h4, .txt-page .db-wrp .content .h4 {
  font-weight: bold;
  font-size: 24px;
  line-height: 117%;
  margin-bottom: 45px;
  color: #000000;
}

/* line 59, dev/sass/custom.scss */
.txt-page .db-wrp .content ul {
  display: flex;
  flex-wrap: wrap;
  gap: 30px 0;
  margin: 30px 0;
}

@media screen and (max-width: 600px) {
  /* line 59, dev/sass/custom.scss */
  .txt-page .db-wrp .content ul {
    margin: 15px 0;
    gap: 15px 0;
  }
}

/* line 69, dev/sass/custom.scss */
.txt-page .db-wrp .content ul li {
  width: 50%;
  padding-right: 30px;
  margin-bottom: 0;
}

@media screen and (max-width: 600px) {
  /* line 69, dev/sass/custom.scss */
  .txt-page .db-wrp .content ul li {
    width: 100%;
  }
}

/* line 78, dev/sass/custom.scss */
.txt-page .db-wrp .content ul._wht {
  gap: 0;
}

/* line 81, dev/sass/custom.scss */
.txt-page .db-wrp .content ul._wht li {
  color: #fff;
}

/* line 87, dev/sass/custom.scss */
.txt-page .db-wrp .content ul._big {
  gap: 0;
}

/* line 90, dev/sass/custom.scss */
.txt-page .db-wrp .content ul._big li {
  width: 50%;
  padding-left: 50px;
  font-weight: bold;
  font-size: 36px;
  position: relative;
  line-height: 1;
}

@media screen and (max-width: 600px) {
  /* line 90, dev/sass/custom.scss */
  .txt-page .db-wrp .content ul._big li {
    width: 100%;
    font-size: 22px;
  }
}

/* line 102, dev/sass/custom.scss */
.txt-page .db-wrp .content ul._big li::before {
  content: '';
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 24px;
  transform: translateY(-50%);
}

@media screen and (min-width: 601px) {
  /* line 114, dev/sass/custom.scss */
  .txt-page .db-wrp .content ul._big li:nth-child(n+3) {
    margin-top: 60px;
  }
}

@media screen and (max-width: 600px) {
  /* line 120, dev/sass/custom.scss */
  .txt-page .db-wrp .content ul._big li:not(:last-child) {
    margin-bottom: 10px;
  }
}

/* line 134, dev/sass/custom.scss */
.txt-page .db-wrp .sidebar {
  width: 29%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: flex-start;
}

@media screen and (max-width: 1280px) {
  /* line 134, dev/sass/custom.scss */
  .txt-page .db-wrp .sidebar {
    width: 100%;
    padding: 20px;
  }
}

/* line 145, dev/sass/custom.scss */
.txt-page .db-wrp .sidebar img {
  margin: auto;
}

@media screen and (min-width: 1281px) {
  /* line 149, dev/sass/custom.scss */
  .txt-page .db-wrp .sidebar img._absolute {
    position: absolute;
    right: 0;
    top: 13px;
    min-width: calc(100% + 50px);
  }
}

/* line 159, dev/sass/custom.scss */
.txt-page .db-wrp .sidebar._blue {
  background-color: #0063A6;
}

@media screen and (max-width: 600px) {
  /* line 159, dev/sass/custom.scss */
  .txt-page .db-wrp .sidebar._blue {
    min-width: calc(100% + 2rem);
    margin-left: -1rem;
  }
}

/* line 168, dev/sass/custom.scss */
.txt-page .db-wrp .sidebar._blueGradient {
  background: linear-gradient(178.92deg, #0063A6 46.27%, #2776ac 56.82%, rgba(70, 134, 177, 0.7) 75.5%, rgba(196, 196, 196, 0) 90%);
}

@media screen and (max-width: 1280px) {
  /* line 168, dev/sass/custom.scss */
  .txt-page .db-wrp .sidebar._blueGradient {
    padding-right: 0;
  }
}

/* line 176, dev/sass/custom.scss */
.txt-page .db-wrp .sidebar .text-arrow {
  margin: auto auto 0;
  color: #fff;
  font-weight: bold;
  font-size: 40px;
  text-align: center;
  padding-bottom: 35px;
  line-height: 120%;
}

@media screen and (max-width: 1280px) {
  /* line 176, dev/sass/custom.scss */
  .txt-page .db-wrp .sidebar .text-arrow {
    font-size: 30px;
  }
}

@media screen and (max-width: 600px) {
  /* line 176, dev/sass/custom.scss */
  .txt-page .db-wrp .sidebar .text-arrow {
    padding-bottom: 0;
  }
}

/* line 191, dev/sass/custom.scss */
.txt-page .db-wrp .sidebar .text-arrow img {
  margin: 34px auto 0;
  animation: 1s linear toBottom infinite;
}

/* line 197, dev/sass/custom.scss */
.txt-page .db-wrp .sidebar .qr-block {
  width: 214px;
  height: 214px;
  background-color: #fff;
  border-radius: 30px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #000000;
  line-height: 33px;
  padding: 20px;
  position: relative;
}

/* line 212, dev/sass/custom.scss */
.txt-page .db-wrp .sidebar .qr-block__img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* line 224, dev/sass/custom.scss */
.txt-page .zag._blue {
  background-color: #0063A6;
  color: #fff;
  margin-bottom: 70px;
}

@media screen and (max-width: 600px) {
  /* line 224, dev/sass/custom.scss */
  .txt-page .zag._blue {
    padding-left: 1rem;
  }
}

/* line 232, dev/sass/custom.scss */
.txt-page .zag._blue .container {
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 23px 0;
}

/* line 239, dev/sass/custom.scss */
.txt-page .zag._blue h2, .txt-page .zag._blue .h2 {
  font-weight: bold;
  font-size: 40px;
  color: #fff;
}

@media screen and (max-width: 600px) {
  /* line 239, dev/sass/custom.scss */
  .txt-page .zag._blue h2, .txt-page .zag._blue .h2 {
    font-size: 26px;
  }
}

/* line 248, dev/sass/custom.scss */
.txt-page .zag._blue .arrows-anim {
  display: flex;
  padding-left: 23px;
}

/* line 252, dev/sass/custom.scss */
.txt-page .zag._blue .arrows-anim img {
  margin-right: 7px;
}

@media screen and (max-width: 600px) {
  /* line 252, dev/sass/custom.scss */
  .txt-page .zag._blue .arrows-anim img {
    max-width: 10px;
    margin-right: 5px;
  }
}

/* line 262, dev/sass/custom.scss */
.txt-page .zag._blue .arrows-anim img:nth-child(2) {
  opacity: 0.8;
}

/* line 266, dev/sass/custom.scss */
.txt-page .zag._blue .arrows-anim img:nth-child(3) {
  opacity: 0.6;
}

/* line 270, dev/sass/custom.scss */
.txt-page .zag._blue .arrows-anim img:nth-child(4) {
  opacity: 0.4;
}

/* line 277, dev/sass/custom.scss */
.txt-page .note {
  margin-top: 70px;
  min-height: 35px;
  display: flex;
  align-items: center;
  padding: 0px 10px 0px 40px;
  background-image: url(../assets/images/new/arrow-red-bold.svg);
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: left center;
  font-size: 18px;
  color: #6B7280;
}

@media screen and (max-width: 600px) {
  /* line 277, dev/sass/custom.scss */
  .txt-page .note {
    font-size: 16px;
    margin-top: 40px;
  }
}

/* line 295, dev/sass/custom.scss */
.txt-page ul {
  display: flex;
  flex-wrap: wrap;
}

/* line 299, dev/sass/custom.scss */
.txt-page ul._wArrows {
  gap: 45px 0;
}

@media screen and (max-width: 600px) {
  /* line 299, dev/sass/custom.scss */
  .txt-page ul._wArrows {
    gap: 20px 0;
  }
}

/* line 304, dev/sass/custom.scss */
.txt-page ul._wArrows .note {
  margin-top: 0;
}

/* line 310, dev/sass/custom.scss */
.txt-page ul._w50 li {
  width: 50%;
}

@media screen and (max-width: 600px) {
  /* line 310, dev/sass/custom.scss */
  .txt-page ul._w50 li {
    width: 100%;
  }
}

/* line 319, dev/sass/custom.scss */
.txt-page .note-img {
  display: flex;
  align-items: flex-start;
  margin-bottom: 70px;
}

@media screen and (max-width: 600px) {
  /* line 319, dev/sass/custom.scss */
  .txt-page .note-img {
    margin-bottom: 30px;
    flex-wrap: wrap;
  }
}

/* line 328, dev/sass/custom.scss */
.txt-page .note-img img {
  width: 91px;
  height: auto;
}

@media screen and (max-width: 600px) {
  /* line 328, dev/sass/custom.scss */
  .txt-page .note-img img {
    margin: 0 auto 10px;
  }
}

/* line 336, dev/sass/custom.scss */
.txt-page .note-img p {
  max-width: calc(100% - 91px);
  padding-left: 48px;
  font-size: 21px;
  color: #6B7280;
  padding-top: 10px;
}

@media screen and (max-width: 600px) {
  /* line 336, dev/sass/custom.scss */
  .txt-page .note-img p {
    max-width: 100%;
    width: 100%;
    font-size: 14px;
    padding-left: 0;
  }
}

/* line 352, dev/sass/custom.scss */
.txt-page .m100 {
  margin: 50px auto;
  display: flex;
}

@media screen and (max-width: 600px) {
  /* line 352, dev/sass/custom.scss */
  .txt-page .m100 {
    margin: 25px auto;
  }
}

/* line 360, dev/sass/custom.scss */
.txt-page .m140 {
  margin: 70px auto;
  display: flex;
}

@media screen and (max-width: 600px) {
  /* line 360, dev/sass/custom.scss */
  .txt-page .m140 {
    margin: 35px auto;
  }
}

/* line 368, dev/sass/custom.scss */
.txt-page .manager {
  display: flex;
  padding: 80px 160px 85px 105px;
  margin-bottom: 40px;
  position: relative;
  filter: drop-shadow(0px 7px 11px rgba(0, 0, 0, 0.25));
}

@media screen and (max-width: 1280px) {
  /* line 368, dev/sass/custom.scss */
  .txt-page .manager {
    padding: 30px;
  }
}

@media screen and (max-width: 600px) {
  /* line 368, dev/sass/custom.scss */
  .txt-page .manager {
    flex-wrap: wrap;
  }
}

/* line 381, dev/sass/custom.scss */
.txt-page .manager::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  clip-path: polygon(0 0, 100% 0, 100% 71%, 92% 100%, 0 99%, 0% 50%);
  background-color: #fff;
  z-index: 1;
}

@media screen and (max-width: 600px) {
  /* line 381, dev/sass/custom.scss */
  .txt-page .manager::before {
    clip-path: polygon(0 0, 100% 0, 100% 87%, 92% 100%, 0 100%, 0% 50%);
  }
}

/* line 396, dev/sass/custom.scss */
.txt-page .manager:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  background-image: url(/assets/images/new/bg-img.png);
  background-position: top right 150px;
  background-repeat: no-repeat;
}

/* line 409, dev/sass/custom.scss */
.txt-page .manager:not(:first-child) {
  margin-top: 140px;
}

@media screen and (max-width: 1280px) {
  /* line 409, dev/sass/custom.scss */
  .txt-page .manager:not(:first-child) {
    margin-top: 70px;
  }
}

/* line 417, dev/sass/custom.scss */
.txt-page .manager._black::before {
  background-color: #021019;
}

/* line 421, dev/sass/custom.scss */
.txt-page .manager._black::after {
  background-image: url(/assets/images/new/bg-img-2.png);
}

/* line 425, dev/sass/custom.scss */
.txt-page .manager._black .manager-content, .txt-page .manager._black .manager-img {
  background-image: url(../assets/images/new/arrow-white-bold.svg);
}

/* line 429, dev/sass/custom.scss */
.txt-page .manager._black .name {
  font-weight: bold;
  color: #fff;
}

/* line 434, dev/sass/custom.scss */
.txt-page .manager._black .position {
  color: #fff;
}

/* line 438, dev/sass/custom.scss */
.txt-page .manager._black a {
  font-size: 24px;
  color: #fff;
}

@media screen and (max-width: 990px) {
  /* line 438, dev/sass/custom.scss */
  .txt-page .manager._black a {
    font-size: 18px;
  }
}

@media screen and (max-width: 600px) {
  /* line 438, dev/sass/custom.scss */
  .txt-page .manager._black a {
    font-size: 14px;
  }
}

/* line 450, dev/sass/custom.scss */
.txt-page .manager-img {
  width: 540px;
  background-image: url(../assets/images/new/arrow-red-bold.svg);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 35px;
  padding-left: 45px;
  position: relative;
  z-index: 3;
}

@media screen and (max-width: 1280px) {
  /* line 450, dev/sass/custom.scss */
  .txt-page .manager-img {
    width: 30%;
    background-size: 20px;
    padding-left: 30px;
  }
}

@media screen and (max-width: 600px) {
  /* line 450, dev/sass/custom.scss */
  .txt-page .manager-img {
    width: 100%;
  }
}

/* line 468, dev/sass/custom.scss */
.txt-page .manager-img img {
  border-radius: 50%;
}

/* line 473, dev/sass/custom.scss */
.txt-page .manager-content {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  padding-left: 75px;
  background-image: url(../assets/images/new/arrow-red-bold.svg);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 35px;
  padding-top: 20px;
  position: relative;
  z-index: 3;
  width: calc(100% - 540px);
}

@media screen and (max-width: 1280px) {
  /* line 473, dev/sass/custom.scss */
  .txt-page .manager-content {
    padding-left: 50px;
    background-size: 20px;
    padding-top: 10px;
    width: 70%;
  }
}

@media screen and (max-width: 600px) {
  /* line 473, dev/sass/custom.scss */
  .txt-page .manager-content {
    width: 100%;
    padding-left: 30px;
  }
}

/* line 499, dev/sass/custom.scss */
.txt-page .manager .name {
  font-size: 36px;
  margin-bottom: 25px;
  color: #111827;
  line-height: 120%;
}

@media screen and (max-width: 1280px) {
  /* line 499, dev/sass/custom.scss */
  .txt-page .manager .name {
    font-size: 28px;
  }
}

@media screen and (max-width: 600px) {
  /* line 499, dev/sass/custom.scss */
  .txt-page .manager .name {
    font-size: 18px;
    margin-bottom: 10px;
  }
}

/* line 513, dev/sass/custom.scss */
.txt-page .manager .position {
  font-size: 18px;
  margin-bottom: 20px;
  color: #CC1D2E;
}

@media screen and (max-width: 600px) {
  /* line 513, dev/sass/custom.scss */
  .txt-page .manager .position {
    font-size: 14px;
  }
}

/* line 522, dev/sass/custom.scss */
.txt-page .manager a {
  margin-top: 15px;
  font-size: 18px;
  color: #111827;
  display: block;
}

@media screen and (max-width: 600px) {
  /* line 522, dev/sass/custom.scss */
  .txt-page .manager a {
    font-size: 14px;
    margin-top: 5px;
  }
}

/* line 531, dev/sass/custom.scss */
.txt-page .manager a:hover {
  text-decoration: underline;
}

/* line 536, dev/sass/custom.scss */
.txt-page .manager-note {
  margin: 40px 0 50px;
  font-size: 20px;
  color: #000000;
  line-height: 1.4;
}

@media screen and (max-width: 600px) {
  /* line 536, dev/sass/custom.scss */
  .txt-page .manager-note {
    margin: 20px 0 30px;
    font-size: 16px;
  }
}

@keyframes toBottom {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
}
